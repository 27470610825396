<script setup lang="ts">
import useKeyboardGlobalEvent from '~/composables/useKeyboardGlobalEvent';

const { setValidation, required } = useValidation();
const { closeModal } = useModalStore();
const { isLoading, setLoadingPending, setLoadingSuccess, setLoadingFailed } =
  useLoading();
const $api = useApi();

const props = defineProps({
  openFoodFactIngredient: {
    type: Object,
    default: () => {},
  },
  onConfirm: {
    type: Function,
    default: () => {},
  },
  quantityInGram: Boolean,
});

const inputSearchRef = ref();
const inputQuantityRef = ref();
const availableBaseFoods = ref([]);
const foodToAddData = reactive({
  foodId: undefined,
  quantity: undefined,
});

watch(
  () => foodToAddData.foodId,
  () => {
    inputQuantityRef.value.focus();
  },
);
const validation = setValidation(foodToAddData, {
  foodId: { required },
  quantity: { required },
});

watch(
  () => props.openFoodFactIngredient,
  async (foodItem) => {
    if (!foodItem) {
      return;
    }
    foodToAddData.quantity = Number(foodItem.quantity_percent);
    fetchBaseFoods(foodItem.label);
  },
  {
    immediate: true,
  },
);

async function fetchBaseFoods(search: String) {
  setLoadingPending();
  try {
    const { data } = await $api.post(`/basefoods/all`, {
      search,
      page: 1,
    });

    availableBaseFoods.value = data;
    setLoadingSuccess();
  } catch (e) {
    setLoadingFailed();
  }
}
async function handleAddIngredientToFood() {
  const baseFood = availableBaseFoods.value.find(
    (rF) => rF.id === foodToAddData.foodId,
  );
  const ingredientToAdd = {
    tags: baseFood.attributes,
    prices: baseFood.prices,
    co2_impact_for_100g: baseFood.co2_impact_for_100g,
    label: getLocaleTranslation(baseFood.translations)?.label,
    description: getLocaleTranslation(baseFood.translations)?.description,
    basefoodId: foodToAddData.foodId,
    foodId: baseFood.foodId,
    ...(props.quantityInGram
      ? { quantity_gram: foodToAddData.quantity }
      : { quantity_percent: foodToAddData.quantity }),
  };
  props.onConfirm(ingredientToAdd);
  closeModal();
}

onMounted(() => {
  setTimeout(() => {
    if (props.openFoodFactIngredient) {
      inputSearchRef.value.setInputValue(
        props.openFoodFactIngredient.label.toLowerCase(),
      );
    }

    inputSearchRef.value?.focus();
  }, 500);
});

useKeyboardGlobalEvent('Enter', async () => {
  inputQuantityRef.value?.blur();
  setTimeout(() => {
    if (validation.value.$invalid) {
      return;
    }
    handleAddIngredientToFood();
  }, 100);
});
</script>

<template>
  <BaseModal title="Ajouter un ingrédient" :width="900">
    <BaseSelect
      ref="inputSearchRef"
      v-model="foodToAddData.foodId"
      placeholder="Tomate, abricot, lait, eau, ..."
      type="text"
      label="Recherche un aliment"
      :options="
        availableBaseFoods.map((p) => ({
          label: `${getLocaleTranslation(p.translations)?.label} - ${getLocaleTranslation(p.translations)?.description}`,
          value: p.id,
        }))
      "
      :remote-call="fetchBaseFoods"
      :is-loading="isLoading"
      clearable
      debounce
    ></BaseSelect>

    <BaseInput
      ref="inputQuantityRef"
      v-model="foodToAddData.quantity"
      type="number"
      :label="`Quantité (${props.quantityInGram ? 'g' : '%'})`"
    ></BaseInput>

    <template #footer>
      <BaseButton text="Annuler" type="secondary" @click="closeModal" />
      <BaseButton
        text="Confirmer"
        @click="handleAddIngredientToFood"
        :disabled="validation.$invalid"
      />
    </template>
  </BaseModal>
</template>

<style scoped></style>
